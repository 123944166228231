import React, {useState, useContext, useEffect} from 'react';
import {QRCodeSVG} from "qrcode.react";
import {useTranslation} from "react-i18next";
import {formatNumber, MARKETING_COMMUNICATION_URL_BY_LANGUAGE} from "../../../../utils";
import VehicleSideBar from "./VehicleSideBar";
import Advertisement from "./Advertisement";
import {useMutation} from "graphql-hooks";
import {generateTemptTokenWithTime} from "../../../choose/utils";
import {DataContext} from "../../../../index";

const SHORTEN_URL_MUTATION = `
    mutation ShortenUrl($data: ShortenUrlInputType!) {
        shortenUrl(data: $data)  {
           ok      
           url   
           error 
        }
    }`;

const VehicleSummary = (props) => {
    const { state} = useContext(DataContext);
    const {vehicle} = props;
    const [communicationShortUrl, setCommunicationShortUrl] = useState('');
    const [shortenUrl, {loading}] = useMutation(SHORTEN_URL_MUTATION)

    const {t} = useTranslation();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const setCommunicationUrl = () => {
        shortenUrl({
            variables: {
                data: {
                    'url': MARKETING_COMMUNICATION_URL_BY_LANGUAGE[state.language?.toLowerCase() || 'nl']
                }
            },
            fetchOptionsOverrides: {
                headers: {
                    "Authorization": generateTemptTokenWithTime()
                }
            },
            onSuccess(result, variables) {
                const shortUrl = result.data.shortenUrl.url
                setCommunicationShortUrl(shortUrl)
            }
        });
    }

    console.log('communicationShortUrl: ', communicationShortUrl)

    useEffect(() => {
        setCommunicationUrl()
    }, [vehicle.reference]);

    const handleSidebarOpen = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    return (
      <React.Fragment>
        <div
          className="absolute bottom-0 bg-white rounded z-10"
          style={{
            right: "3.125vw",
            width: "26.667vw",
            boxShadow: "0px 0px 24px #0000001A",
          }}
        >
          <div className="px-14 pt-14 mb-12 border-b border-gray-light">
            <div className="text-2xl font-semibold mb-2">
              {vehicle.make} {vehicle.model}
            </div>
            <div className="text-lg font-regular mb-6">{vehicle.version}</div>
            <div className="font-semibold text-xl flex flex-row items-start mb-6">
              <span className="text-sm mt-0.5 mr-1">€</span>{" "}
              <span className="text-3xl mr-1">
                {formatNumber(vehicle.price)}
              </span>{" "}
              <span className="text-sm mt-0.5">{t("BTWi")}</span>
            </div>

              {vehicle?.salonDeal !== null && <div className="mb-6"><Advertisement labelKey={vehicle?.salonDeal?.key}/></div>}

            <div
              className="pb-12 mb-12 border-b border-gray-light flex flex-row"
              onClick={handleSidebarOpen}
            >
              <div className="flex-grow text-lg font-semibold">
                {t("Specifications")}
              </div>
              <i className="fa fa-chevron-down text-lg font-regular"></i>
            </div>
            {vehicle.equipmentGroups && (
              <div className="pb-12 flex flex-row" onClick={handleSidebarOpen}>
                <div className="flex-grow text-lg font-semibold">
                  {t("Options and equipment")}
                </div>
                <i className="fa fa-chevron-down text-lg font-regular"></i>
              </div>
            )}
          </div>

          <div className="pb-14 px-14">
            <div className="text-xl font-semibold mb-7">
              {t("See yourself behind the wheel? We too!")}
            </div>
              <div className="grid grid-cols-2 gap-4">

                  <div className="flex flex-col items-center border border-gray-100 p-2 rounded-lg">
                      <div className="mb-4">
                          <QRCodeSVG
                              value={`${vehicle.url}&contact-form-open&campaign=SalonDeal&utm_source=Salon2023&utm_medium=Kiosk&utm_campaign=Salondeals`}
                              fgColor={"#183C74"}
                              bgColor={"transparent"}
                              style={{height: "100%", width: "auto"}}
                          />
                      </div>
                      <div className="text-sm font-regular">
                          {t("PromoDetailQRScanText", {
                              dealerName: vehicle.dealer.legalName,
                          })}
                      </div>
                  </div>

                  {loading ? <div className="bg-gray-lighter animate-pulse h-full w-full"/> : <div className="flex flex-col items-center w-full border border-gray-100 p-2 rounded-lg">
                      <div className="mb-4">
                          <QRCodeSVG
                              value={communicationShortUrl}
                              fgColor={"#183C74"}
                              bgColor={"transparent"}
                              style={{height: "100%", width: "auto"}}
                          />
                      </div>
                      <div className="text-sm font-regular">
                          {t('Scan the QR-code with your smartphone to subscribe to newsletter.')}
                      </div>
                  </div>}

              </div>
          </div>
        </div>
          <VehicleSideBar
              isOpen={sidebarOpen}
              close={handleSidebarClose}
              vehicle={vehicle}
              communicationShortUrl={communicationShortUrl}
          />
      </React.Fragment>
    );
}

export default VehicleSummary
