import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../utils';

const CheckboxWithText = ({ onClick, checked, label, className, errorText, additional }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const hasText = additional?.type === 'text';
  const hasImage = additional?.type === 'img';

  return (
    <div className={`flex justify-start cursor-pointer ${hasText ? 'items-start' : 'items-center font-regular'} ${className} `} onClick={(e) => onClick(!isChecked, e)}>
      <div className={`flex items-center justify-center checkbox ${isChecked ? 'checkbox-checked' : 'checkbox-default'}`}>
        <div className={`flex items-center justify-center transition duration-150 ease-in-out ${isChecked ? 'opacity-100' : 'opacity-0'}`}>
          <i className='far fa-check text-white text-14 pt-0.5' />
        </div>
      </div>

      {hasImage && (
        <div className='flex items-center justify-center ml-4 w-10 h-8'>
          <img src={additional.value} className='object-cover' />
        </div>
      )}

      <div className="ml-4 w-full truncate">
        <div className='whitespace-pre-wrap text-lg text-gray-darker font-regular truncate'>{capitalizeFirstLetter(label)}</div>
        {hasText && <div className='font-regular text-14 mt-1 text-gray-darker leading-22 flex-wrap whitespace-pre-wrap'>{additional?.value}</div>}
      </div>
    </div>
  );
};

CheckboxWithText.defaultProps = {
  checked: false,
  label: '',
  onClick: undefined,
  className: ''
};

export default CheckboxWithText;