import React from 'react'
import { QRCodeSVG } from "qrcode.react";

export default function QRBlock(QRUrl, color) {
  return (
    <QRCodeSVG
      value={QRUrl}
      fgColor={color}
      bgColor={"transparent"}
      style={{ height: "80%", width: "auto" }}
    />
  );
}
