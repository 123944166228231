import {filter, find, flatten, forEach, map, uniq} from 'lodash';

export const hasFilter = (filters, key, value) => {
    return find(filters, (f) => f.key === key && f.value === value)
}

const mapValueToString = (value) => {
    if (Array.isArray(value)) {
        return mapArrayToString(value);
    }

    return value;
};

export const mapArrayToString = (arr, separator = ',') => {
    let result = '';
    forEach(arr, (item) => {
        if (result === '') {
            result = item;
        } else {
            result = `${result}${separator}${item}`;
        }
    });
    return result;
};

export const getGroupedFilters = (filters) => {
    const groupedFilters = [];

    const makeModels = flatten(map(filter(filters, (f) => f.key === 'makeModel'), (f) => f.value));
    if (makeModels.length > 0) groupedFilters.push({type: 'makeModelFamily', values: makeModels});

    const fuels = flatten(map(filter(filters, (f) => f.type === 'fuel'), (f) => f.value));
    if (fuels.length > 0) groupedFilters.push({type: 'fuels', values: fuels});


    return groupedFilters;
};


export const convertFiltersToVariables = (filters) => {
    if (!filters || !filters.length === 0) {
        return {}
    }

    const groupedFilters = [];

    const after = find(filters, (f) => f.key === 'after')
    if (after) groupedFilters.push({key: 'after', values: after.value});

    const makeModels = flatten(map(filter(filters, (f) => f.key === 'makeModel'), (f) => f.value));
    if (makeModels.length > 0) groupedFilters.push({key: 'makeModelFamily', values: makeModels});

    const fuels = flatten(map(filter(filters, (f) => f.key === 'fuel'), (f) => f.value));
    if (fuels.length > 0) groupedFilters.push({key: 'fuels', values: fuels});

    const labels = flatten(map(filter(filters, (f) => f.key === 'labels'), (f) => f.value));
    if (labels.length > 0) groupedFilters.push({key: 'labels', values: labels});

    const prices = flatten(map(filter(filters, (f) => f.key === 'price'), (f) => f.value));
    if (prices.length > 0) {
        const min = prices[0]
        const max = prices[1]
        if (min > 0)
            groupedFilters.push({key: 'priceMin', values: min});

        if (max > 0)
            groupedFilters.push({key: 'priceMax', values: max});
    }

    const objectKeys = uniq(map(groupedFilters, (f) => f.key));
    const result = []

    forEach(objectKeys, (key) => {
        const tmpResult = flatten(
            map(
                filter(groupedFilters, (f) => f.key === key),
                (f) => f.values
            )
        );

        if (tmpResult.length > 0) result.push({[key]: tmpResult.join()})
    })

    return Object.assign({}, ...result)
}
