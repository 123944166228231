import { map, isString } from "lodash";
import {LANGUAGES} from "./settings";
import {filterAddHandler, listTypeSetHandler} from "./components/promo/actions";

export const useAsset = (resource) => {
  return require(`/${resource}`).default;
};

export const toggleDisableBackgroundScroll = (disabled) => {
  //if (hasDocument()) {
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];

  if (disabled) {
    if (html) html.classList.add("overflow-hidden");
    if (body) body.classList.add("overflow-y-scroll");
  }

  if (!disabled) {
    if (html && html.classList.contains("overflow-hidden")) {
      html.classList.remove("overflow-hidden");
    }
    if (body && body.classList.contains("overflow-y-scroll"))
      body.classList.remove("overflow-y-scroll");
  }
  //}
};

export const formatNumber = (number, minDigits = 0, maxDigits = 2) => {
  let nr = parseFloat(number);
  return nr.toLocaleString("nl-NL", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
};

export const formatPrice = (price, currency = "€") => {
  return `${currency}${formatNumber(price)}`;
};

export const map_vehicles = (vehicles) => {
  const mapped = map(vehicles, (vehicle) => {
    return {
      ...vehicle,
      images: JSON.parse(vehicle.images),
    };
  });
  return mapped;
};

export const isElementInViewport = (el) => {
  let r, html;
  if (!el || 1 !== el.nodeType) {
    return false;
  }
  html = document.documentElement;
  r = el.getBoundingClientRect();
  return !!r && r.bottom >= 0 && r.top <= html.clientHeight;
};


export const capitalizeFirstLetter = (string) => {
  if (!string) return '';

  if (isString(string)) return string[0].toUpperCase() + string.slice(1);

  return string;
};

export const resolveLanguageToSwitchCode = (currentLanguage) => {
  const possibleLanguages = Object.keys(LANGUAGES);
  const index = possibleLanguages.indexOf(currentLanguage);
  possibleLanguages.splice(index, 1);
  return possibleLanguages[0]
}

export const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);
      if(node){
        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
      }else{
        reject('SOMETHING WRONG')
      }

    });

export const initialAnswer = {
  en: "I don't care",
  nl: "Ik geef er niet om",
  fr: "Cela m'est égal",
}

export const VEHICLE_PROMO_LIST_TYPES = {
  "AAPLUS": "AAPLUS",
  "MYWAY": "MYWAY",
}

export const LABELS = {
  "AAPLUS": "audi_approved_plus",
  "MYWAY": "myway_(vw_skoda_audi_seat)",
  "SALONDEAL": "salondeal",
}

export const IMAGE_SETS = {
  "AAPLUS": 'aap',
  "MYWAY": 'mwb2c',
}

export const getImageSet = (key) => {
  if(!key) return 'naked';

  if(IMAGE_SETS[key]) return IMAGE_SETS[key];

  return 'naked';
}

export const determineVehicleListType = (makeParam) => {
  return makeParam === 'audi' ? VEHICLE_PROMO_LIST_TYPES.AAPLUS : VEHICLE_PROMO_LIST_TYPES.MYWAY;
}

export const MARKETING_COMMUNICATION_URL_BY_LANGUAGE = {
  'nl': 'https://share-eu1.hsforms.com/1hcISdcC2Q-qxAVYRAMbO5Qf0x0l',
  'fr': 'https://share-eu1.hsforms.com/1MVY7IZUoRIeCRHzQpTdDPgf0x0l',
}