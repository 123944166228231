import React, {useContext} from "react";
import {DataContext} from "../../../../index";
import {useTranslation} from "react-i18next";
import {filter, find, map, reverse, sortBy} from 'lodash';
import {filterAddHandler, filterDeleteHandler, filterReplaceHandler, filtersSetHandler} from "../../actions";
import {hasFilter} from "./utils";
import {formatPrice, LABELS} from "../../../../utils";
import CheckboxWithText from '../../../../common/CheckboxWithText';
import RadioWithText from '../../../../common/RadioWithText';

const Filters = () => {
    const {state, dispatch} = useContext(DataContext);
    const {makes, fuels, priceRanges} = state.promo.filterData
    const {filters} = state.promo
    const {t} = useTranslation();

    const mostSearchedMakes = reverse(sortBy(filter(makes, (f) => f.priority > 0), ['priority']));
    const otherMakes = filter(makes, (f) => f.priority === 0);

    const handeBudgetRangeClick = (key, value) => {
        const currentFilter = find(filters, (f) => f.key === key)
        if (!currentFilter) {
            dispatch(filterAddHandler(key, value))
        } else {
            if (currentFilter.value[0] === value[0] && currentFilter.value[1] === value[1]) {
                dispatch(filterDeleteHandler(key, value))
            } else {
                dispatch(filterReplaceHandler(key, value))
            }

        }
    }

    const handleFilterClick = (key, value) => {
        if (!hasFilter(filters, key, value)) {
            dispatch(filterAddHandler(key, value))
        } else {
            dispatch(filterDeleteHandler(key, value))
        }
    }

    const handleApplyFilters = () => {
        dispatch(filtersSetHandler(filters))
    }

    const formatBudgetRangeLabel = (range) => {
        if (range.min === 0) {
            return t('Less than') + ' ' + formatPrice(range.max)
        } else if (range.max === 0) {
            return t('More than') + ' ' + formatPrice(range.min)
        } else {
            return t('Between') + ' ' + formatPrice(range.min) + ' ' + t('and') + ' ' + formatPrice(range.max)
        }
    }


    return (
        <div className="pr-20">
            <div className="mb-12 text-lg font-normal">{t('Deals')}</div>
            <div className='pb-6 mb-12 border-b border-gray-light'>
                <CheckboxWithText label={t('Salon deals')}
                                  className="mb-6"
                                  checked={find(filters, (f) => f.key === "labels" && f.value === LABELS.SALONDEAL)}
                                  onClick={() => handleFilterClick('labels', LABELS.SALONDEAL)}
                />
            </div>


            <div className="mb-12 text-lg font-normal">{t('Price ranges')}</div>
            {/*<div className="mb-12 text-sm font-regular text-gray-dark">{t('Ranges from')} min: {formatPrice(price?.minValue)} - max: {formatPrice(price?.maxValue)}</div>*/}

            <div className='pb-6 mb-12 border-b border-gray-light'>
                {map(priceRanges, (range, index) => {
                    return (
                        <RadioWithText
                            key={`range-${index}`}
                            classNames="mb-6"
                            label={formatBudgetRangeLabel(range)}
                            checked={find(filters, (f) => f.key === "price" && f.value[0] === range.min && f.value[1] === range.max)}
                            enabled={range.available}
                            onClick={() => handeBudgetRangeClick('price', [range.min, range.max])}
                        />
                    )
                })}
            </div>

            <div className="mb-12 text-lg font-normal">{t('Fuel')}</div>
            <div className='pb-6 mb-12 border-b border-gray-light'>
                {map(fuels, (fuel) => {
                    const selected = find(filters, (f) => f.key === "fuel" && f.value === fuel.key)
                    return (
                        <CheckboxWithText key={fuel.key}
                                          label={fuel.description ? fuel.description : fuel.key}
                                          className={`mb-6 ${(!fuel.available) ? 'opacity-50' : ''}`}
                                          checked={selected}
                                          onClick={() => fuel.available ? handleFilterClick('fuel', fuel.key) : undefined}
                        />

                    )
                })}
            </div>


            {mostSearchedMakes.length > 0 && otherMakes.length > 0 &&
                <React.Fragment>
                    <div className="mb-10 text-lg font-normal">{t('Make')}</div>
                    <div className="mb-5 text-lg text-gray-dark font-regular uppercase">{t('Most searched makes')}</div>
                    <div className='pb-10'>{map(mostSearchedMakes, (make) => {
                        const makeSelected = find(filters, (f) => f.key === "makeModel" && f.value === make.key)
                        return (
                            <CheckboxWithText key={make.key}
                                              label={make.description}
                                              className={`mb-6 ${(!make.available) ? 'opacity-50' : ''}`}
                                              checked={makeSelected}
                                              onClick={() => make.available ? handleFilterClick('makeModel', make.key) : undefined}
                            />
                        )
                    })}</div>
                    <div className="mb-5 text-lg text-gray-dark font-regular uppercase">{t('Other makes')}</div>
                    <div className='pb-10'>{map(otherMakes, (make) => {
                        const makeSelected = find(filters, (f) => f.key === "makeModel" && f.value === make.key)
                        return (
                            <CheckboxWithText key={`other-make-${make.key}`}
                                              label={make.description}
                                              className={`mb-6 ${(!make.available) ? 'opacity-50' : ''}`}
                                              checked={makeSelected}
                                              onClick={() => make.available ? handleFilterClick('makeModel', make.key) : undefined}
                            />
                        )
                    })}</div>
                </React.Fragment>}

        </div>
    );
};

export default Filters;
