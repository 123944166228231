import React from 'react';

const Switch = ({options, activeOption, onChange}) => {
    if(!options || options?.length < 2){
        return null;
    }

    return (
        <div className="relative grid grid-cols-2 gap-2 h-14 px-2 py-2 bg-gray-lighter btn-pill">
            {/* Animated background */}
            <div className={`absolute w-36 top-2 bottom-2 btn-primary transition-all duration-900 transform btn-pill ${activeOption === options[0].value ? 'left-2' : 'left-40'}`}/>

            {options.map((option, index) => {
                const isActive = option.value === activeOption
                return <div key={`${option.value}-${option.label}-${index}`} className={`flex items-center justify-center relative w-36 ${isActive ? 'btn-primary' : 'cursor-pointer'} btn-pill`}
                            onClick={() => onChange(option.value)}
                >
                    {option.label}
                </div>
            })}
        </div>
    );
};

export default Switch;
