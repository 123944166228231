import {determineVehicleListType, LABELS, VEHICLE_PROMO_LIST_TYPES} from "../utils";
import {filterAddHandler, filterDeleteHandler, listTypeSetHandler} from "../components/promo/actions";
import {useContext} from "react";
import {DataContext} from "../index";

export const useVehicleListType = () => {
    const {state, dispatch} = useContext(DataContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const makeParam = urlSearchParams.get('make')?.toLowerCase();

    const applyTheme = (themeClass) => {
        if(document){
            document.body.className = ''; // Remove all classes
            document.body.classList.add(themeClass);
        }
    }

    const addTheme = () => {
        const listType = determineVehicleListType(makeParam);
        dispatch(listTypeSetHandler(listType))

        // Add theme classes to body
        if (listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS) {
            applyTheme('audi-theme')
            dispatch(filterDeleteHandler('labels', LABELS.MYWAY))
            dispatch(filterAddHandler('labels', LABELS.AAPLUS))
        } else {
            applyTheme('myway-theme')
            dispatch(filterDeleteHandler('labels', LABELS.AAPLUS))
            dispatch(filterAddHandler('labels', LABELS.MYWAY))
        }
    }

    return {
        addTheme
    }
};