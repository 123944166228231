import React from 'react';
import {Link} from "react-router-dom";

const Root = () => {
    return (
        <div className="flex flex-col">
            {/*<Link to={`/wall`} className="border p-5 m-5">*/}
            {/*    <span>BIG SCREEN WALL</span>*/}
            {/*</Link>*/}

            <Link to={`/promo/?excluded-make=audi`} className="border p-5 m-5">
                <span>MY WAY - PROMO DEALS</span>
            </Link>

            <Link to={`/promo?make=audi`} className="border p-5 m-5">
                <span>AUDI - PROMO DEALS</span>
            </Link>

            {/*<Link to={`/choose`} className="border p-5 m-5">*/}
            {/*    <span>MY WAY MATCH</span>*/}
            {/*</Link>*/}

            {/*<a className="border p-5 m-5" href="https://qa.mywaybuy.be/default/inspection?mode=kiosk">MYWAY BUY</a>*/}

        </div>
    );
};

export default Root;
