import React, {useContext} from 'react';
import {DataContext} from "../../../../index";
import {VEHICLE_PROMO_LIST_TYPES} from "../../../../utils";
import {DEFAULT_LANGUAGE} from "../../../../settings";
import warranty1AudiNl from '../../../../assets/advertisements/audi/1-warranty-nl.png';
import warranty1AudiFr from '../../../../assets/advertisements/audi/1-warranty-fr.png';
import warranty2AudiNl from '../../../../assets/advertisements/audi/2-warranty-nl.png';
import warranty2AudiFr from '../../../../assets/advertisements/audi/2-warranty-fr.png';
import warranty3AudiNl from '../../../../assets/advertisements/audi/3-warranty-nl.png';
import warranty3AudiFr from '../../../../assets/advertisements/audi/3-warranty-fr.png';
import warranty1MyWayNl from '../../../../assets/advertisements/myway/1-warranty-nl.png';
import warranty1MyWayFr from '../../../../assets/advertisements/myway/1-warranty-fr.png';
import warranty2MyWayNl from '../../../../assets/advertisements/myway/2-warranty-nl.png';
import warranty2MyWayFr from '../../../../assets/advertisements/myway/2-warranty-fr.png';
import warranty3MyWayNl from '../../../../assets/advertisements/myway/3-warranty-nl.png';
import warranty3MyWayFr from '../../../../assets/advertisements/myway/3-warranty-fr.png';

import discount500AudiNl from '../../../../assets/advertisements/audi/500-discount-nl.png';
import discount500AudiFr from '../../../../assets/advertisements/audi/500-discount-fr.png';
import discount1000AudiNl from '../../../../assets/advertisements/audi/1000-discount-nl.png';
import discount1000AudiFr from '../../../../assets/advertisements/audi/1000-discount-fr.png';
import discount1500AudiNl from '../../../../assets/advertisements/audi/1500-discount-nl.png';
import discount1500AudiFr from '../../../../assets/advertisements/audi/1500-discount-fr.png';
import discount500MyWayNl from '../../../../assets/advertisements/myway/500-discount-nl.png';
import discount500MyWayFr from '../../../../assets/advertisements/myway/500-discount-fr.png';
import discount1000MyWayNl from '../../../../assets/advertisements/myway/1000-discount-nl.png';
import discount1000MyWayFr from '../../../../assets/advertisements/myway/1000-discount-fr.png';
import discount1500MyWayNl from '../../../../assets/advertisements/myway/1500-discount-nl.png';
import discount1500MyWayFr from '../../../../assets/advertisements/myway/1500-discount-fr.png';

import fuel250AudiNl from '../../../../assets/advertisements/audi/250-fuel-nl.png';
import fuel250AudiFr from '../../../../assets/advertisements/audi/250-fuel-fr.png';
import fuel500AudiNl from '../../../../assets/advertisements/audi/500-fuel-nl.png';
import fuel500AudiFr from '../../../../assets/advertisements/audi/500-fuel-fr.png';
import fuel750AudiNl from '../../../../assets/advertisements/audi/750-fuel-nl.png';
import fuel750AudiFr from '../../../../assets/advertisements/audi/750-fuel-fr.png';

import fuel250MyWayNl from '../../../../assets/advertisements/myway/250-fuel-nl.png';
import fuel250MyWayFr from '../../../../assets/advertisements/myway/250-fuel-fr.png';
import fuel500MyWayNl from '../../../../assets/advertisements/myway/500-fuel-nl.png';
import fuel500MyWayFr from '../../../../assets/advertisements/myway/500-fuel-fr.png';
import fuel750MyWayNl from '../../../../assets/advertisements/myway/750-fuel-nl.png';
import fuel750MyWayFr from '../../../../assets/advertisements/myway/750-fuel-fr.png';

import priceReductionAudiNl from '../../../../assets/advertisements/audi/special-offer-nl.png';
import priceReductionAudiFr from '../../../../assets/advertisements/audi/special-offer-fr.png';
import priceReductionMyWayNl from '../../../../assets/advertisements/myway/special-offer-nl.png';
import priceReductionMyWayFr from '../../../../assets/advertisements/myway/special-offer-fr.png';

const LABEL_KEYS = {
    WARRANTY_1Y: "sd-1y-warranty",
    WARRANTY_2Y: "sd-2y-warranty",
    WARRANTY_3Y: "sd-3y-warranty",
    DISCOUNT_500: "sd-discount-500",
    DISCOUNT_1000: "sd-discount-1000",
    DISCOUNT_1500: "sd-discount-1500",
    FUELCARD_250: "sd-fuelcard-250",
    FUELCARD_500: "sd-fuelcard-500",
    FUELCARD_750: "sd-fuelcard-750",
    PRICE_REDUCTION: "sd-price-reduction",
};

const LANGUAGES = {
    "NL": 'nl',
    "FR": 'fr',
}

const LABELS = {
    [LABEL_KEYS.WARRANTY_1Y]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: warranty1AudiNl,
            [LANGUAGES.FR]: warranty1AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: warranty1MyWayNl,
            [LANGUAGES.FR]: warranty1MyWayFr
        }
    },
    [LABEL_KEYS.WARRANTY_2Y]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: warranty2AudiNl,
            [LANGUAGES.FR]: warranty2AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: warranty2MyWayNl,
            [LANGUAGES.FR]: warranty2MyWayFr
        }
    },
    [LABEL_KEYS.WARRANTY_3Y]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: warranty3AudiNl,
            [LANGUAGES.FR]: warranty3AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: warranty3MyWayNl,
            [LANGUAGES.FR]: warranty3MyWayFr
        }
    },
    [LABEL_KEYS.DISCOUNT_500]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: discount500AudiNl,
            [LANGUAGES.FR]: discount500AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: discount500MyWayNl,
            [LANGUAGES.FR]: discount500MyWayFr
        }
    },
    [LABEL_KEYS.DISCOUNT_1000]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: discount1000AudiNl,
            [LANGUAGES.FR]: discount1000AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: discount1000MyWayNl,
            [LANGUAGES.FR]: discount1000MyWayFr
        }
    },
    [LABEL_KEYS.DISCOUNT_1500]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: discount1500AudiNl,
            [LANGUAGES.FR]: discount1500AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: discount1500MyWayNl,
            [LANGUAGES.FR]: discount1500MyWayFr
        }
    },
    [LABEL_KEYS.FUELCARD_250]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: fuel250AudiNl,
            [LANGUAGES.FR]: fuel250AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: fuel250MyWayNl,
            [LANGUAGES.FR]: fuel250MyWayFr
        }
    },
    [LABEL_KEYS.FUELCARD_500]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: fuel500AudiNl,
            [LANGUAGES.FR]: fuel500AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: fuel500MyWayNl,
            [LANGUAGES.FR]: fuel500MyWayFr
        }
    },
    [LABEL_KEYS.FUELCARD_750]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: fuel750AudiNl,
            [LANGUAGES.FR]: fuel750AudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: fuel750MyWayNl,
            [LANGUAGES.FR]: fuel750MyWayFr
        }
    },
    [LABEL_KEYS.PRICE_REDUCTION]: {
        [VEHICLE_PROMO_LIST_TYPES.AAPLUS]: {
            [LANGUAGES.NL]: priceReductionAudiNl,
            [LANGUAGES.FR]: priceReductionAudiFr
        },
        [VEHICLE_PROMO_LIST_TYPES.MYWAY]: {
            [LANGUAGES.NL]: priceReductionMyWayNl,
            [LANGUAGES.FR]: priceReductionMyWayFr
        }
    }

}

const Advertisement = ({labelKey}) => {
    const {state} = useContext(DataContext);
    const language = state?.language || DEFAULT_LANGUAGE;
    const listType = state?.promo?.listType || VEHICLE_PROMO_LIST_TYPES.MYWAY;

    if(!labelKey) return null

    return (
        <img src={LABELS?.[labelKey]?.[listType]?.[language]} alt={labelKey}/>
    );
};

export default Advertisement;
