import React, { useState, useEffect, useContext, useRef } from "react";
import { filter, includes, map, times } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { keys, first } from "lodash";
import VehicleCard from "./VehicleCard";
import { DataContext } from "../../../../index";
import {
  currentScrollPositionSetHandler,
  nextBatchStartFetchingHandler,
  vehiclesSetHandler,
} from "../../actions";
import { convertFiltersToVariables } from "../filters/utils";
import { PROMO_BATCH_SIZE } from "../../../../settings";
import { getImageSet } from "../../../../utils";

const PROMO_VEHICLES_SEARCH_QUERY = `query PromoVehicleSearch($first: Int, $after: String, $makeModelFamily: String, $fuels: String, $priceMin: Decimal, $priceMax: Decimal) {
    promoVehicleSearch(first: $first, after: $after, makeModelFamily: $makeModelFamily, fuels: $fuels, priceMin: $priceMin, priceMax: $priceMax)  {
       totalCount
       filters {
           makes {
               key
               description
               priority
               models {
                   key
                   description
                   priority
               }
           }
           fuels {
               key
               description
               translations
               priority
           }
          priceRanges {
            min
            max
            count
          }
       }
       edges {
           node {
               reference
               make
               model
               version
               price
               firstRegistrationYear
               mileage
               warranty
               pk
               kw
               gearbox
               fuel
               co2
               images {
                   key
                   url
                   priority
               }

               salonDeal {
                   key
                   name
               }
           }
       }
       pageInfo {
           hasNextPage
           hasPreviousPage
           startCursor
           endCursor
       }
   }
}`;

const VEHICLES_SEARCH_QUERY = `query VehicleSearch($first: Int, $after: String, $makeModelFamily: String, $fuels: String, $priceMin: Decimal, $priceMax: Decimal, $labels: String, $excludedMake: String, $settings: VehicleSearchSettings) {
    vehicleSearch(first: $first, after: $after, makeModelFamily: $makeModelFamily, fuels: $fuels, priceMin: $priceMin, priceMax: $priceMax, labels: $labels, excludedMake: $excludedMake, settings: $settings)  {
       totalCount
       filters {
           makes {
               key
               description
               priority
               models {
                   key
                   description
                   priority
               }
           }
           fuels {
               key
               description
               translations
               priority
           }
          priceRanges {
            min
            max
            count
          }
       }
       edges {
           node {
               reference
               make
               model
               version
               price
               firstRegistrationYear
               mileage
               warranty
               hp
               kw
               gearbox
               fuel
               co2
               images {
                   key
                   url
                   priority
               }
               salonDeal {
                   key
                   name
               }
           }
       }
       pageInfo {
           hasNextPage
           hasPreviousPage
           startCursor
           endCursor
       }
   }
}`;

const VehicleList = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const [error, setError] = useState(undefined);

  const scrollRef = useRef(null);
  const isInitialMount = useRef(true);

  const { loading, setLoading } = props;
  const vehicles = state.promo.vehicles;
  const currentFilters = state.promo.filters;

  const gridLayoutParams = {
    nCols: 5, //6,
    pxContainer: 3.125, // horizontal padding container
    gapPercentageOfScreenWidth: 2.5, //1.667,
  };
  const gridViewWidth = 100 - gridLayoutParams.pxContainer * 2;
  const totalGapWidth =
    gridLayoutParams.gapPercentageOfScreenWidth * (gridLayoutParams.nCols - 1);
  const gridColWidth = (gridViewWidth - totalGapWidth) / gridLayoutParams.nCols;

  useEffect(() => {
    // only on initial load, not on back from detail...
    if (state.promo.vehicles === undefined) {
      fetchVehicles(currentFilters);
    }
  }, [state.promo.vehicles]);

  useEffect(() => {
    if (
      state.promo.nextBatchStartCursor !== undefined &&
      state.promo.startFetchNextBatch &&
      !state.promo.fetching
    ) {
      fetchNextVehiclesBatch();
    }
  }, [
    state.promo.nextBatchStartCursor,
    state.promo.startFetchNextBatch,
    state.promo.fetching,
  ]);

  useEffect(() => {
    // reset scrollposition when back from detail page
    if (scrollRef.current && state.promo.currentScrollPosition) {
      scrollRef.current.scrollLeft(state.promo.currentScrollPosition.left);
    }
  }, [scrollRef]);

  useEffect(() => {
    // ugly work around, but it works ...
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchVehicles(currentFilters);
    }
  }, [state.language, state.promo.filters]);

  const fetchVehicles = (filters) => {
    let variables = convertFiltersToVariables(filters);
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has("make")) {
      let make = urlSearchParams.get("make");
      variables = {
        ...variables,
        makeModelFamily: make,
      };
    }

    if (urlSearchParams.has("excluded-make")) {
      let excludedMake = urlSearchParams.get("excluded-make");
      variables = {
        ...variables,
        excludedMake: excludedMake,
      };
    }

    variables = {
      ...variables,
      settings: {
        imageSetKey: getImageSet(state?.promo?.listType),
      },
    };

    const isNextBatch = includes(keys(variables), "after");

    if (!isNextBatch) setLoading(true);

    variables["first"] = PROMO_BATCH_SIZE;

    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": state.language,
      },
      body: JSON.stringify({
        query: VEHICLES_SEARCH_QUERY,
        variables: variables,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const vehicles = map(res.data.vehicleSearch.edges, (n) => n.node);
        const pageInfo = res.data.vehicleSearch.pageInfo;
        const filterData = {
          language: state.language,
          ...res.data.vehicleSearch.filters,
        };
        const totalCount = res.data.vehicleSearch.totalCount;

        dispatch(
          vehiclesSetHandler(
            vehicles,
            pageInfo,
            filterData,
            totalCount,
            isNextBatch
          )
        );

        setLoading(false);
      })
      .catch((error) => {
        setError("ERROR");
        setLoading(false);
      });
  };

  const fetchNextVehiclesBatch = () => {
    const { pageInfo, batchFetching } = state.promo;
    if (pageInfo && pageInfo.hasNextPage) {
      const filters = [
        ...currentFilters,
        { key: "after", value: state.promo.nextBatchStartCursor },
      ];
      fetchVehicles(filters);
    }
  };

  if (loading || vehicles === undefined) {
    return (
      <React.Fragment>
        <div
          id="loadingVehicleGrid"
          className="grid grid-rows-2 grid-flow-col"
          style={{ gap: `${gridLayoutParams.gapPercentageOfScreenWidth}vw` }}
        >
          {times(10, (index) => {
            return (
              <div
                className="flex flex-col bg-gray-lighter animate-pulse rounded-4 h-full"
                style={{ minWidth: `${gridColWidth}vw`, minHeight: 465 }}
                key={index}
              ></div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <div
        className="w-full grid grid-cols-1 place-content-center"
        style={{ height: "87.408vh" }}
      >
        <div className="justify-self-center">{error}</div>
      </div>
    );
  }

  // fix to render empty cards , when filter results only 2 vehicles.. the grid expects minimum of 'cords' to show properly
  const emptyCards = [];
  const numberOfEmptyCards = vehicles.length < 24 ? 24 - vehicles.length : 0;
  for (let i = 0; i < numberOfEmptyCards; i++) {
    emptyCards.push("empty");
  }
  const vehicleCards =
    vehicles.length < 24 ? [...vehicles, ...emptyCards] : [...vehicles];

  return (
    <React.Fragment>
      {/*<div>loaded vehicles: {vehicles.length}/{state.promo.totalCount} with {state.promo.doubles.length} doubles</div>*/}
      <Scrollbars
        ref={scrollRef}
        autoHeight={true}
        autoHeightMax={1080}
        autoHide={false}
        autoHideTimeout={3000}
        autoHideDuration={500}
        onScrollFrame={(values) => {
          // Fetch new batch when scrolled more then 50% of container
          if (values.left >= 0.1) {
            dispatch(nextBatchStartFetchingHandler());
          }
        }}
        onScrollStop={() => {
          if (scrollRef && scrollRef.current) {
            const left = scrollRef.current.getScrollLeft();
            const top = scrollRef.current.getScrollTop();
            dispatch(currentScrollPositionSetHandler(top, left));
          }
        }}
      >
        <div
          id="vehicleGrid"
          className="grid grid-rows-2 grid-flow-col"
          style={{
            gap: `${gridLayoutParams.gapPercentageOfScreenWidth}vw`,
            marginBottom: "60px",
          }}
        >
          {map(vehicleCards, (vehicle, index) => {
            if (vehicle !== "empty") {
              return (
                <div
                  className="flex flex-col"
                  style={{
                    minWidth: `${gridColWidth}vw`,
                    maxWidth: `${gridColWidth}vw`,
                  }}
                  key={index}
                >
                  {vehicle !== "empty" && <VehicleCard vehicle={vehicle} />}
                </div>
              );
            }
          })}
          {loading && (
            <React.Fragment>
              <div
                className="flex flex-col bg-gray-lighter animate-pulse rounded-4"
                style={{ minWidth: `${gridColWidth}vw` }}
              ></div>
              <div
                className="flex flex-col bg-gray-lighter animate-pulse rounded-4"
                style={{ minWidth: `${gridColWidth}vw` }}
              ></div>
            </React.Fragment>
          )}
        </div>
      </Scrollbars>
    </React.Fragment>
  );
};

export default VehicleList;
