import React, {useContext} from "react";
import OverlayWithSidebar from "../../../../common/OverlayWithSidebar";
import { useTranslation } from "react-i18next";
import Filters from "./Filters";
import {filtersResetHandler} from "../../actions";
import {DataContext} from "../../../../index";
import {Scrollbars} from "react-custom-scrollbars-2";

const FiltersSideBar = (props) => {
    const {UIState, isOpen, close} = props;
    const {dispatch} = useContext(DataContext);

    const { t } = useTranslation();

    const renderSidebar = () => {
        return (
            <div className='flex flex-col h-full'>
                <div className='grid grid-cols-1 pt-14 pb-12 pr-14 pl-36'>
                    <div className='flex items-center justify-center justify-self-end w-10 h-10 rounded-full cursor-pointer bg-gray-lighter' onClick={close}>
                        <i className='fal fa-times text-gray-darker text-22 text-lg mt-0.5'/>
                    </div>
                    <div className='text-left text-2xl font-normal text-gray-darkest justify-self-start'>Filter</div>
                </div>

                <div className="flex-1 max-h-full h-full overflow-y-scroll pt-0 pb-12 pl-36 pr-16 no-scrollbar">
                    <Scrollbars
                        autoHeight={true}
                        autoHeightMin={1080}
                        //autoHeightMax={1080}
                        //autoHide
                        //autoHideTimeout={1000}
                        //autoHideDuration={200}
                    >
                        <Filters UIState={UIState} close={close}/>
                    </Scrollbars>
                </div>

                <div className='flex items-center border-t border-gray-light px-36 text-base font-normal py-12'>
                    <div className='py-4 px-6 cursor-pointer mr-4'>
                        <span className='truncate ' onClick={() => dispatch(filtersResetHandler())}>{t('Reset filters')}</span>
                    </div>

                    <div className='flex-grow py-4 px-6 btn-primary cursor-pointer text-center rounded-lg' onClick={() => close()}>
                        <span className='truncate'>{t('View results')}</span>
                    </div>
                </div>

            </div>
        );
    };

    return (
        <React.Fragment>
            <OverlayWithSidebar
                isOpen={isOpen}
                onClose={close}
                animation={true}
                sidebar={renderSidebar()}
                sidebarPosition="right"
                //sidebarWidthClass="w-34vw"
                sidebarWidthClass="w-50vw"
                overlayClass="bg-gray-dark"
            />
            {/* <Filters UIState={UIState} /> */}
        </React.Fragment>
    );
};

export default FiltersSideBar;
