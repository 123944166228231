import React, {useContext} from "react";
import {filtersResetHandler} from "../../actions";
import {DataContext} from "../../../../index";
import { useTranslation } from "react-i18next";

const FiltersBar = (props) => {
  const {state, dispatch} = useContext(DataContext);
  const { openSidebar } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="flex flex-row items-center space-x-5 text-xs font-normal">

        <div onClick={() => dispatch(filtersResetHandler())}>
          {t('Reset filters')}
        </div>
        <div className={`btn btn-pill flex flex-row items-center ${state?.promo?.filters?.length > 1 ? 'btn-primary' : '' }`} onClick={openSidebar}>
          <i className='fa fa-sliders mr-3'></i>
          <div className='text-base font-normal'>{t('Filter')}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FiltersBar;
